.center {
   margin: 0;
   position: absolute;
   top: 50%;
   left: 50%;
   -ms-transform: translate(-50%, -50%);
   transform: translate(-50%, -50%);
}

@media screen and (min-width: 1000px){
   .center {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 70%;
      -ms-transform: translate(-117%, -50%);
      transform: translate(-117%, -50%);
   }
}

html{
   font-size: 16px;
}

body{
   margin: 0;
   height: 100%;
   overflow-x: hidden;
   overflow-y: auto;
   background-color: var(--surface-a);
   color: var(--text-color);
   -webkit-font-smoothing: antialiased;
   font-family: var(--font-family);
}

a{
   text-decoration: none;
   color: var(--blue-500);
   font-weight: 600;
   cursor: pointer;
}

#form{
   position: sticky;
   top: 3.6rem;
   z-index: 9997;
   background: var(--surface-a);
}

@media screen and (max-width:575px) {
   #form{
      position: initial;
      
   }
}

@media screen and (max-width:960px) {
   #form{
      top: 3.4rem !important;
   }
}

div .p-dialog-content {
   padding-bottom: 0 !important;
}